"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _styles = require("@material-ui/core/styles");
var _default = (0, _styles.createTheme)({
  palette: {
    primary: {
      // main: '#7B9B46'
      main: '#97BF29'
    },
    secondary: {
      main: '#9c9e9f'
    },
    error: {
      main: '#F5222D'
    },
    success: {
      main: '#52C41A'
    },
    text: {
      primary: '#4F4F4F',
      secondary: '#828282'
    },
    externo: {
      main: '#97BF29',
      secondary: '#9c9e9f'
    },
    background: {
      "default": '#FBFBFB',
      border: '#D9D9D9'
    }
  },
  typography: {
    fontFamily: ['Inter', '-apple-system', 'Roboto', '"Segoe UI"', '"Helvetica Neue"', 'Arial', 'sans-serif'].join(','),
    fontSize: 14
  },
  props: {
    MuiTypography: {
      variantMapping: {
        body2: 'span'
      }
    }
  },
  overrides: {
    MuiTypography: {
      h1: {
        fontSize: '1.7rem',
        lineHeight: '130%',
        fontWeight: 600,
        '@media (min-width: 0px) and (max-width: 840px)': {
          fontSize: '1.5rem',
          textAlign: 'center'
        }
      },
      h2: {
        fontSize: '1.5rem',
        lineHeight: '150%',
        fontWeight: 500,
        '@media (min-width: 0px) and (max-width: 840px)': {
          fontSize: '1.2rem',
          textAlign: 'center'
        }
      },
      h3: {
        fontSize: '1.2rem',
        lineHeight: '160%',
        fontWeight: 500,
        '@media (min-width: 0px) and (max-width: 840px)': {
          fontWeight: 600,
          textAlign: 'center'
        }
      },
      h4: {
        fontSize: '1.1rem',
        lineHeight: '160%',
        fontWeight: 500,
        '@media (min-width: 0px) and (max-width: 840px)': {
          fontSize: '1.1rem',
          textAlign: 'center'
        }
      },
      h5: {
        fontSize: '0.9rem',
        lineHeight: '100%',
        fontWeight: 600
      },
      h6: {
        fontSize: '0.8rem',
        lineHeight: '150%',
        fontWeight: 600
      }
    },
    MuiOutlinedInput: {
      adornedEnd: {
        paddingRight: '0px'
      }
    },
    MuiIconButton: {
      root: {
        padding: '8px'
      }
    },
    MuiTabPanel: {
      root: {
        padding: '8px',
        paddingRight: '0px',
        paddingLeft: '0px'
      }
    }
  }
});
exports["default"] = _default;