import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@material-ui/styles';
import { Provider } from 'react-redux';
import { store } from './store';
import './styles.css';
import theme from './theme';
import 'react-notifications/lib/notifications.css';
import { NotificationContainer } from 'react-notifications';
import { SttTranslateHook } from '@stt-componentes/core';
import Home from './paginas';

class App extends Component {
	render() {
		return (
			<div>
				<NotificationContainer />
				<Provider store={store}>
					<ThemeProvider theme={theme}>
						<SttTranslateHook.I18nContextProvider modulo={'eeg'}>
							<Home />
						</SttTranslateHook.I18nContextProvider>
					</ThemeProvider>
				</Provider>
			</div>
		);
	}

	getChildContext() {
		return {
			currentLanguage: this.props.currentLanguage,
			modulo: this.props.modulo
		};
	}
}

App.childContextTypes = {
	currentLanguage: PropTypes.string.isRequired,
	modulo: PropTypes.string.isRequired
};

export default App;
