module.exports = {
    acompanhanteRegistrado: 'Acompanhante já registrado',
    alertaResponsavelCadsus: 'Os dados do responsável foram importados do Cartão Nacional do SUS. Preencha seu contato e clique em "Cadastrar" para continuar.',
    atencaoAdicaoAnexos: 'Anexe aqui os documentos do paciente para a avaliação clínica.',
    atencaoAnexosObrigatorios: 'Os documentos de identidade com CPF, cartão nacional de saúde, comprovante de residência, termo de responsabilidade SMS, assinado e resultado da gasometria arterial são obrigatórios.', 
    avaliacaoClinica: 'Avaliação clínica',
    buscandoResponsaveis: 'Buscando responsáveis',
    buscarResponsavel: 'Buscar responsável',
    cadastroAcompanhante: 'Deseja cadastrar um novo responsavel?',
    cancelado: 'Cancelado',
    cancelamento:'Cancelamento',
    cancelando: 'Cancelando',
    cardiopatias: 'Cardiopatias',
    carregandoAvaliacaoClinica: 'Carregando avaliação clínica',
    doencaAutoimune: 'Doença autoimune',
    doencaNeurologica: 'Doença neurológica',
    doencasPulmonares: 'Doenças pulmonares',
    erroImprimirAvaliacaoClinica: 'Ocorre um erro ao imprimir a avaliação. Tente novamente mais tarde.',
    exFumante: 'Ex-fumante',
    fluxoOxigenio: 'Fluxo de Oxigênio',
    fumante: 'Fumante',
    gasometriaArterial: 'Gasometria arterial',
    historicoSaude: 'Histórico de saúde',
    identificacaoResponsavel: 'Identificação do responsável',
    imprimindoAvaliacaoClinica: 'Imprimindo avaliação clínica',
    informacoesClinicas: 'Informações clínicas',
    neoplasia: 'Neoplasia',
    novaAvaliacaoClinica: 'Nova avaliação clínica',
    novaSolicitacao: 'Nova solicitação',
    nuncaFumou: 'Nunca fumou',
    outrasCheckbox: 'Outra(s)',
    outroParentesco: 'Outro parentesco',
    pacienteInternado: 'Paciente internado',
    parecerSituacao: 'Parecer da situação',
    parentesco: 'Grau de parentesco',
    pesquisaAvaliacoesClinicas: 'Pesquisa de Avaliações Clínicas',
    processoAvaliacaoClinica: 'Processo de avaliação clínica',
    rascunho: 'Rascunho',
    responsavelAdicionado: 'Responsável adicionado',
    resultados: 'Resultados',
    salvarCadastro: 'Deseja salvar o cadastro?',
    solicitacaoAvaliacaoClinica: 'Solicitação de avaliação clínica',
    tempoUsoOxigenio: 'Tempo de uso do oxigênio (h/dia)',
    traqueostomia: 'Traqueostomia',
    tratamentosAndamento: 'Tratamentos em andamento',
    veracidadeParecerSituacao: 'Assumo a veracidade das informações acima descritas, com base em visita domiciliar, documentação apresentada, bem como das declarações recebidas do paciente.',
    redeOxigenoterapia: 'Rede de Oxigenoterapia',
    responsavelSalvoSucesso: 'Responsável salvo com sucesso',
    gasometriaColetada: 'A gasometria arterial foi coletada com o paciente usando oxigênio?',
    textoConfirmacaoEnvioSolicitacao: 'Você confirma o envio da avaliação clínica?',
    textoConfirmarRascunhoSolicitacao: 'Deseja salvar um rascunho da avaliacao clínica?',
    encaminhado: 'Encaminhado',
};
